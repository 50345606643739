<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Salary Slabs</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Salary Slabs</li>
            </ol>
        </nav>
        <b-container fluid>
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            <b-row>
                <b-col lg="6" class="mb-1rem">
                    <router-link
                        v-if="hasPermission('CAN_MANAGE_SALARY_SLABS')"
                        :to="{name: 'create-salary-slab'}"
                        class="btn btn-primary text-right"
                        tag="button"
                    >
                        <font-awesome-icon icon="plus"></font-awesome-icon>&nbsp; Create Salary Slab
                    </router-link>
                </b-col>
                   <!-- <b-col lg="6" class="mb-1rem text-right">
                    <b-form-group
                        label="Search"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="sm"
                        label-for="filterInput"
                        class="mb-0"
                        style="font-weight:bold; font-size:1rem !important;"
                    >
                        <b-input-group size="md">
                            <b-form-input
                                v-model="filter"
                                type="search"
                                id="filterInput"
                                placeholder="Type Name to Search"
                                v-on:keyup.enter="onFiltered"
                                @search="clearSearch"
                            ></b-form-input>
                            <b-input-group-append>
                                <b-button
                                    :disabled="!filter"
                                    @click="onFiltered"
                                    class="btn btn-primary"
                                >Search</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col> -->
            </b-row>

            <b-table
                show-empty
                striped
                hover
                :items="items"
                selectable
                select-mode="single"
                @row-selected="onRowSelected"
                :fields="fields"
                responsive
            >
                <template v-slot:cell(actions)="row" >
                    <span class="actions" v-if="!row.item.deleted_at">
                        <router-link class="circle" v-b-tooltip.hover title="Edit" :to="{ name: 'edit-salary-slab', params: { id:  row.item.id}}" >
                            <font-awesome-icon
                                v-if="hasPermission('CAN_MANAGE_SALARY_SLABS')"
                                icon="pen"
                            ></font-awesome-icon>
                        </router-link>
                        <span
                            v-if="hasPermission('CAN_MANAGE_SALARY_SLABS')"
                            @click="info(row.item, row.index, $event.target)"
                            class="circle text-danger ml-1"
                            v-b-tooltip.hover title="Deactivate"
                            v-b-modal.modal-center
                        >
                            <font-awesome-icon icon="ban"></font-awesome-icon>
                        </span>
                    </span>
                    <span class="actions">
                        <span v-b-tooltip.hover class="circle ml-1" v-b-modal.modal-center @click="openHistory(row.item,$event.target)" title="history"> 
                            <font-awesome-icon
                                icon="history" 
                                style="cursor: pointer;"
                            />
                        </span>
                    </span>
                </template>
                 <template v-slot:cell(status)="row">
                    <h6 v-if="!row.item.deleted_at"><b-badge variant="success">ACTIVE</b-badge></h6>
                    <h6 v-else><b-badge variant="danger">INACTIVE</b-badge></h6>
                </template>
            </b-table>
            
            <b-modal
                :id="infoModal.id"
                :title="infoModal.title"
                centered
                size="sm"
                @hide="resetInfoModal"
            >
            <p v-html="message" class="float-left"></p>
            <div slot="modal-footer" class="w-100">
                <b-button class="mt-3 float-right" @click="cancel()">No</b-button>
                <b-button
                    class="mt-3 float-right"
                    variant="danger"
                    v-on:click="deactivateSlab(infoModal.content)"
                    style="margin-right:10px;"
                >Yes</b-button>
            </div>
        </b-modal>

        <!-- History modal -->
            <b-modal 
                :id="history.id" 
                centered  
                size="xl" 
                hide-footer
            >
                <template #modal-title>
                    <span class="h4 font-weight-bold">{{history.title}}</span> 
                </template>
                <b-table
                    show-empty
                    striped
                    hover
                    :items="history.rows"
                    :fields="history.fields"
                    no-local-sorting
                    responsive
                >
                    <template v-slot:cell(conditions)="row">
                        <div class="row">
                            <div class="col-md-12">
                                <span v-if="JSON.parse(row.item.conditions).length>1">Default Formula : </span>{{ JSON.parse(row.item.conditions)[JSON.parse(row.item.conditions).length-1].formula }}
                            </div>
                        </div>
                        <div class="row mt-3" v-for="(condition, index) in JSON.parse(row.item.conditions).slice(0, JSON.parse(row.item.conditions).length-1)" :key="index">
                            <div class="col-md-12" v-for="(value,name,index2) in condition" :key="index2">
                                <span>{{ str_title(name) }} {{ index+1 }}: {{ value }}</span>
                            </div>
                        </div>
                    </template>
                </b-table>
            </b-modal>
        </b-container>
    </div>
</template>

<script>
import NotFound from "../../errors/NotFound";
import default_error from "../../../assets/js/global";
export default {
    components: {
        NotFound
    },
    data() {
        return {
            urlBody: {
                query: "",
                sort_by: "",
                order: ""
            },
            api_error: "",
            sortBy: "name",
            sortDesc: false,
            fields: [
                { key: "name", sortable: true },
                { key: "min_salary", sortable: true },
                { key: "max_salary", sortable: true },
                { label: "Created By", key: "created_employee.full_name", sortable: true },
                { key: "created_at", sortable: true },
                { key: "status" },
                { key: "actions", label: "Actions" }
            ],
            items: null,
            access: false,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            infoModal: {
                id: "modal-sm",
                title: "",
                content: ""
            },
            message: '',
            filter:null,
            history: {
                id: 'slab-history-modal',
                title: 'History',
                fields: [
                    { key: "name" },
                    { key: "min_salary"},
                    { key:"max_salary"},
                    { key: "updated_by_name", label:"Updated By", class: "text-nowrap"},
                    { key: "updated_at", class: "text-nowrap"},
                ],
                rows: []
            }
        };
    },
    mounted() {
        this.access = this.hasPermission("CAN_MANAGE_SALARY_SLABS");
        this.getSalarySlabs();
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },
        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },
        clearSearch (event) {
            if (event.target.value === "") {
                if (!this.filter) {
                    this.api_error = "";
                    this.getSalarySlabs();
                }
            }
        },
        onFiltered() {
            if (this.filter) {
                this.getSalarySlabs({query:this.filter});
            }
        },
        onRowSelected(items) {
            if(!items[0].deleted_at) {
                var slab_id= items[0].id;
                this.$router
                .push({
                    name: 'employee-salaries',
                    query: { slab_id:  slab_id},
                }).catch(()=>{})
            }
            
        },
        info(item, index, button) {
            this.infoModal.title = `Delete Confirmation`;
            this.message = 'Are you sure you want to Deactivate <b>'+item.name+'</b>?';
            this.infoModal.content = JSON.stringify(item, null, 2);
            this.$root.$emit("bv::show::modal", this.infoModal.id, button);
        },
        resetInfoModal() {
            this.infoModal.title = "";
            this.infoModal.content = "";
        },
        deactivateSlab: function(slab) {
            this.startProgressBar();
            this.axios
                .post(
                    this.$api.delete_salary_slab,
                    JSON.parse(slab),
                    this.getAuthHeaders()
                )
                .then(() => {
                    this.$root.$emit("bv::hide::modal", this.infoModal.id);
                    this.deleted = "salary slab Deleted Successfully";
                    this.getSalarySlabs();
                    this.updateProgressBar(true);
                })
                .catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.$root.$emit("bv::hide::modal", this.infoModal.id);
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        },
        cancel: function() {
            this.$root.$emit("bv::hide::modal", this.infoModal.id);
        },
        getSalarySlabs: function(params) {
            this.startProgressBar();
            var query = this.getAuthHeaders();
            query["params"] = this.deleteEmptyKeys(params);
            this.axios.get(this.$api.get_salary_slabs, query)
                .then(response => {
                    this.items = response.data.data;
                    this.api_error = "";
                    this.updateProgressBar(true);
                }).catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.items = [];
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        },

        openHistory(item, button) {
            this.history.rows = JSON.parse(item.history);
            this.$root.$emit("bv::show::modal", this.history.id, button);
        }
    }
};
</script>
<style lang="scss">
@import "../../../assets/css/custom.scss";
#slab-history-modal .modal-body {
    padding: 0 !important;
}
</style>
